export const experienceData = [
  {
    title: "Network Technical",
    duration: "Jan 2019 - Jun 2019",
    location: "Indonesia, Diskominfo",
    description:
      "During my internship at Kominfo as a network technician, I gained valuable hands-on experience in designing, installing, and maintaining computer networks. I learned how to troubleshoot various network issues and worked with a team to ensure that all systems were up and running smoothly.",
    icon: "assets/img/icon/resume-icon1.png", // URL ke icon yang sesuai
  },
  {
    title: "Freelance",
    duration: "2021 - until now",
    location: "Front End Web Development",
    description:
      "As a freelance front-end web developer, I specialize in creating beautiful, functional, and responsive websites for clients. I have expertise in HTML, CSS, JavaScript, and various front-end frameworks such as React, Vue, and Angular. I work closely with clients to understand their needs and design websites that meet their goals and expectations. I am committed to delivering high-quality work and building long-term relationships with my clients.",
    icon: "assets/img/icon/resume-icon1.png", // URL ke icon yang sesuai
  },
  {
    title: "Participant MSIB at Nurul Fikri Acedemy",
    duration: "Febuary 2024 - July 2024",
    location: "Fullstack Web Development",
    description:
      "Awarded as the Best Participant in the Certified Independent Study and Internship Program (MSIB) organized by Kampus Merdeka, Batch 6. During this program, which spanned from February to June 2024, exceptional skills and dedication in Fullstack Web Development were demonstrated at NF Academy. This achievement reflects a strong commitment to excellence, the ability to tackle complex problems, and effective collaboration with peers and mentors to deliver outstanding projects",
    icon: "assets/img/icon/resume-icon1.png", // URL ke icon yang sesuai
  },
];

export const educationData = [
  {
    title: "Vocational High School",
    duration: "2017 - 2020",
    location: "SMKN 3 Bogor",
    description:
      "I attended SMK Negeri 3 Bogor, a vocational high school that focused on preparing students for a career in various fields. The school provided a comprehensive curriculum that included vocational skills, technical knowledge, and general education.",
    icon: "assets/img/icon/resume-icon2.png", // URL ke icon yang sesuai
  },
  {
    title: "An Undergraduate Degree in Computer Science",
    duration: "2021 - OnGoing",
    location: "Indonesian Computer University",
    description:
      "I am currently enrolled in Indonesian Computer University, where I am pursuing my Bachelor's Degree in Computer Science. The university is known for its excellent programs in the field of computer science and technology, providing students with a comprehensive education that includes both theoretical and practical knowledge.",
    icon: "assets/img/icon/resume-icon2.png", // URL ke icon yang sesuai
  },
];
